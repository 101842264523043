const ddRumScript = (
  { init = {}, globalContext = {} },
  serialize,
  landingInfo,
) => `(function(h,o,u,n,d) {
    h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
    d=o.createElement(u);d.async=1;d.src=n
    n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
    })(window,document,'script','https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js','DD_RUM')
    window.DD_RUM.onReady(function() {
      window.DD_RUM.init(${serialize(init, { isJSON: true })});
      window.DD_RUM.setUser({
        id: '${landingInfo.userId || 'uknown'}',
      })
      window.DD_RUM.setGlobalContext(${serialize(
        { ...globalContext, ...landingInfo },
        {
          isJSON: true,
        },
      )})
 })`;

module.exports = {
  ddRumScript,
};
